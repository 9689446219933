import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

const ImageText = loadable(() => import("./modules/imageText"));
const StatementBlock = loadable(() => import("./modules/statementBlock"));
const CardBlock = loadable(() => import("./modules/cardBlock"));
const ProductCarousel = loadable(() => import("./modules/productCarousel"));

const ModuleZone = ({ modules }) => {
  const component = modules.map((item) => {
    switch (item._type) {
      case "imageText":
        return <ImageText {...item} />;
      case "statementBlock":
        return <StatementBlock {...item} />;
      case "cardBlock":
        return <CardBlock {...item} />;
      case "productCarousel":
        return <ProductCarousel {...item} />;
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
