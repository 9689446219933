import React from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line
import { useHeaderQuery } from "@querys/useHeaderQuery";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { SanityImage } from "@components/sanity/sanityImage";
import { useScroll, motion, useTransform } from "framer-motion";
import { StockistButton } from "@components/UI/stockistButton";

const IndexPage = ({ data: { page } }) => {
  const { pageModules, banner, seo } = page || {};
  const { stockistButtonText, buyButton } = useHeaderQuery();

  const { scrollY } = useScroll();
  const y1 = useTransform(scrollY, [0, 10000], [0, -2000]);
  const y2 = useTransform(scrollY, [0, 12000], [0, -2000]);

  return (
    <Layout className=" overflow-x-hidden ">
      <PageMeta {...seo} />
      <div className="relative">
        <div className="h-screen flex items-end  md:items-center  relative max-w-[78vw] mx-auto     ">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="  z-20 pt-20 pb-10 md:pb-0"
          >
            <h2 className="blockH1 text-blue-dark max-w-[300px] md:max-w-[516px]">
              {banner?.title}
            </h2>
            <p className="blockH6 font-medium max-w-[280px] md:max-w-[600px] mt-5">
              {banner?.subtitle}
            </p>
            <div className="flex md:hidden space-x-6 md:space-x-2 mt-10">
              <a className="button-dark " href={buyButton?.url}>
                {buyButton?.text.toUpperCase()}
              </a>
              <StockistButton stockistButtonText={stockistButtonText} />
            </div>
          </motion.div>
        </div>
        <motion.div
          style={{ y: y1 }}
          className="w-[80vw] h-[80vw]    bg-white z-0 rounded-full absolute top-20 lg:-top-72 -right-10 lg:-right-40 "
        />
        <motion.div
          style={{ y: y1 }}
          className="w-[8vw] h-[8vw]   bg-white z-0 rounded-full absolute -bottom-20 left-[20%]"
        />
        <motion.div
          style={{ y: y2 }}
          className="w-[40vw] h-[40vw] scale-50  bg-white z-0 rounded-full absolute -bottom-[80%] -left-[18%]"
        />
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
          className="absolute top-10 md:top-32 -right-14 lg:-right-28 2xl:right-[8%] z-10 "
        >
          <div className="w-[75vw] md:w-[50vw] 2xl:w-[40vw] h-auto relative overflow-hidden">
            <SanityImage
              image={banner?.featuredImage}
              className=" w-full h-full object-cover"
            />
          </div>
        </motion.div>
      </div>
      <div className="hidden lg:block h-[200px]"></div>
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      banner {
        title
        subtitle
        featuredImage {
          ...Image
        }
      }
      pageModules: modules {
        ...PageModules
      }
    }
  }
`;
